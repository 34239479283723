import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  clearAthleteInfo,
  clearInsurance,
  createInsurance,
  removeTicketFromCart,
} from '../../redux/actions/eventActions';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { codiceFiscaleRegExp } from '../../constants/regExp';
import { getUserDetails } from '../../redux/actions/userActions';
import Protected from '../../components/Protected';
import { getCart } from '../../redux/actions/storeActions';
import Title from '../../components/Title';
import InsuranceImg from '../../images/insurance/insurance.jpg';

const Insurance = () => {
  let [formSent, setFormSent] = useState(false);
  const { insurance, error } = useSelector((state) => state.createInsurance);
  const { tickets, loading } = useSelector((state) => state.cart);
  const ticket = tickets?.filter((t) => t.with_insurance)[0];
  const { user } = useSelector((state) => state.userDetails);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserDetails());
    dispatch(getCart());
  }, [dispatch]);

  const onSubmit = (formData) => {
    dispatch(createInsurance(formData));
    dispatch(getCart());
    setFormSent(true);
  };

  const onClick = (e) => {
    dispatch(clearInsurance());
    dispatch(clearAthleteInfo());
    dispatch(removeTicketFromCart(ticket?.id));
  };

  return (
    <main className='insurancePage'>
      <Title title='Assicurazione' />
      <Protected />
      {insurance && formSent && !loading && <Navigate to={'../store/cart'} />}
      {!ticket && !loading && <Navigate to={'../store/cart'} />}
      {!user?.insurance?.expire_date <= ticket?.end_date && (
        <Navigate to={'../store/cart'} />
      )}
      <h2>Compila la tua assicurazione</h2>
      <section className='content'>
        <img src={InsuranceImg} alt='' />
        <div>
          <small>Inserisci nome, cognome e codice fiscale per procedere</small>
          {error && <p className='error'>{error}</p>}
          <form onSubmit={handleSubmit(onSubmit)}>
            <section className={`${errors.firstName ? 'wrong' : ''}`}>
              <input
                type='text'
                {...register('firstName', {
                  required: 'Campo obbligatorio',
                  maxLength: {
                    value: 30,
                    message: 'Nome troppo lungo',
                  },
                })}
                placeholder=' '
              />
              <label>Nome</label>
              {errors.firstName && (
                <p className='error'>{errors.firstName.message}</p>
              )}
            </section>

            <section className={`${errors.lastName ? 'wrong' : ''}`}>
              <input
                type='text'
                {...register('lastName', {
                  required: 'Campo obbligatorio',
                  maxLength: {
                    value: 30,
                    message: 'Cognome troppo lungo',
                  },
                })}
                placeholder=' '
              />
              <label>Cognome</label>
              {errors.lastName && (
                <p className='error'>{errors.lastName.message}</p>
              )}
            </section>
            <section className={`${errors.CF ? 'wrong' : ''}`}>
              <input
                id='CF'
                {...register('CF', {
                  required: 'Campo obbligatorio',
                  pattern: {
                    value: codiceFiscaleRegExp,
                    message: 'Codice fiscale non valido',
                  },
                })}
                type='text'
                placeholder=' '
              />
              <label htmlFor='CF'>Codice Fiscale</label>
              {errors.CF && <p className='error'>{errors.CF.message}</p>}{' '}
            </section>

            <input type='submit' value='Procedi' className='btn' />
          </form>

          <Link
            className='deleteTicket'
            to='../store/cart'
            onClick={(e) => onClick(e)}>
            Elimina biglietto
          </Link>
        </div>
      </section>
    </main>
  );
};

export default Insurance;
